<template>
  <div class="project-card-skeleton md:flex-row flex-col flex flex-nowrap">
    <div class="project-card-img"></div>
    <div class="relative p-6 w-full">
      <div class="card-content pb-12">
        <div class="title text-borderColor card-title"></div>
        <div class="text-size-20 description mt-12"></div>
        <div class="text-size-20 description mt-4"></div>
        <div class="text-size-20 description mt-4"></div>
      </div>
      <div class="w-full flex justify-end">
        <div class="button"></div>
      </div>
    </div>
  </div>
</template>

