<template>
  <div class="project-card-container md:flex-row flex-col flex flex-nowrap">
    <div class="project-card-img" :style="`background: url(${img})`"></div>
    <div class="relative p-6 w-full">
      <div class="card-content pb-12">
        <div class="title text-borderColor card-title">
          {{ title }}
        </div>
        <div class="text-size-20 card-description mt-4">
          {{ description }}
        </div>
      </div>
      <router-link :to="to">
        <div class="project-btn">
          <i class="fas fa-arrow-right text-bluePrimary"></i>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["img", "title", "description", "to"],
};
</script>