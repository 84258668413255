<template>
  <div class="shipping-price-container layout-padding">
    <PageTitle
      class="my-8"
      :title="content.clientsAndProject"
      :childOne="content.wholesaleCollaboration"
      routeOne=""
      :childTwo="content.clientsAndProject"
      routeTwo="/whitelabel/projects"
    />
    <div class="my-8 grid grid-cols-2 gap-10" v-if="loading">
      <div class="col-span-2 lg:col-span-1" v-for="index in 12" :key="index">
        <ProjectCardSkeleton />
      </div>
    </div>
    <div class="my-8 grid grid-cols-2 gap-10" v-else>
      <div
        class="col-span-2 lg:col-span-1"
        v-for="project in projects"
        :key="project.id"
      >
        <ProjectCard
          :img="project.image"
          :title="project.title"
          :description="project.description"
          :to="{ name: 'projectDetails', params: { id: project.id } }"
        />
      </div>
    </div>
    <div
      class="
        flex flex-wrap
        justify-between
        items-center
        projects-end-card
        w-full
      "
    >
      <div class="text-size-16 lora-bold my-2 me-6">
        {{ content.requestQuote }}
      </div>
      <router-link to="/whitelabel/request">
        <button
          class="
            header-btn
            my-2
            text-borderColor
            hover:text-white hover:bg-secondaryColor
            lora-bold
            whitespace-nowrap
            bg-white
            duration-300
            w-full
          "
        >
          {{ content.calculatePriceAndOrder }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import ProjectCard from "/src/components/ui/WhiteLabel/ProjectCard.vue";
import ProjectCardSkeleton from "/src/components/ui/Skeletons/ProjectCardSkeleton.vue";
export default {
  components: { ProjectCard, ProjectCardSkeleton },
  inject: ["content"],
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    projects() {
      return this.$store.getters["projects/projects"];
    },
  },
  async mounted() {
    await this.$store.dispatch("projects/projects");
    this.loading = false;
  },
};
</script>